import React from 'react'
import { graphql } from 'gatsby'
import ProductTemplate from 'components/templates/TourTemplate/TourTemplateShared'

const CapriPage = () => {
  const products = {
    0: {
      productId: 'CPRXPRNC',
      productName: 'bookingTemplate.capri.title',
      productImages: {
        0: 'tour/capri/capri.jpg',
      },
      productLink: 'http://pompeiify.rezgo.com/details/293106/capri-small-tour-group',
      productMaps: 'https://www.google.com/maps/d/embed?mid=1VefGPt6w4pycQnoRI-inm9jRKST3TwHF&hl=it',
      available: false,
    },
  }

  const content = {
    overview: 'bookingTemplate.capri.overview',
    itineraryContent: 'bookingTemplate.capri.itineraryContent',
    firstTime: 'bookingTemplate.capri.firstTime',
    duration: 'bookingTemplate.capri.duration',
    tPrice: 'bookingTemplate.capri.tPrice',
    queue: 'bookingTemplate.capri.queue',
    hint: 'bookingTemplate.capri.hint',
    place: 'bookingTemplate.capri.place',
    faqLink: '/capri-faq',
  }

  return (
    <>
      <ProductTemplate products={products} content={content} />
    </>
  )
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["translation"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default CapriPage
